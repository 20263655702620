import React from 'react'
import styles from './page-header.module.css'

const PageHeader = ({ title, subtitleAfter, subtitleBefore }) => {
  return (
    <header className={styles.pageHeader}>
      <div className="grid" >
        {subtitleBefore && (
          <p className={`${styles.pageHeaderSubtitle} ${styles.pageHeaderSubtitleBefore}`}>
            {subtitleBefore}
          </p>
        )}
        <h1 className={styles.pageHeaderTitle}>
          {title}
        </h1>
        {subtitleAfter && (
          <p className={`${styles.pageHeaderSubtitle} ${styles.pageHeaderSubtitleAfter}`}>
            {subtitleAfter}
          </p>
        )}
      </div>
    </header >
  )
}

export default PageHeader
