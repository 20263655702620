import React from 'react'
import PageMeta from 'components/page/page-meta'
import PageHeader from 'components/page/page-header'
import PageContent from 'components/page/page-content'

const Page = ({ title, subtitleBefore, subtitleAfter, description, path, body, layout, className, children }) => {
  return (
    <div className={className} style={{ width: '100%' }}>
      <PageMeta
        title={title}
        description={description}
        path={path}
      />
      {title && layout !== false && (
        <PageHeader
          title={title}
          subtitleBefore={subtitleBefore}
          subtitleAfter={subtitleAfter}
        />
      )}
      {body ? (
        <PageContent>
          {body}
        </PageContent>
      ) : children}
    </div>
  )
}

export default Page
