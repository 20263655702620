import React from 'react'
import { graphql } from 'gatsby'
import Site from 'components/site'
import Page from 'components/page'

const PageTemplate = ({ data }) => {
  const { mdx } = data

  return (
    <Site>
      <Page
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
        path={mdx.fields.path}
        body={mdx.body}
      />
    </Site>
  )
}

export default PageTemplate

export const query = graphql`
  query Page($path: String!) {
    mdx(fields: { path: { eq: $path } }) {
      id
      body
      fields {
        path
      }
      frontmatter {
        title
        description
      }
    }
  }
`
