import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styles from './page-content.module.css'

const PageContent = ({ children }) => (
  <div className={styles.pageContent}>
    <MDXRenderer>
      {children}
    </MDXRenderer>
  </div>
)

export default PageContent