import React from 'react'
import Helmet from 'react-helmet'
import useSite from 'hooks/use-site'

/**
 * Page specific HTML metadata
 */
const PageMeta = ({ title, description, path }) => {
  const { siteMetadata } = useSite()

  return (
    <Helmet titleTemplate={`%s · ${siteMetadata.title}`}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`${siteMetadata.canonicalUrl}${path}`} />
    </Helmet >
  )
}

export default PageMeta